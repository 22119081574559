<template>
  <div class="bg-blue-50 px-6">
    <div class="bg-white rounded-lg shadow-md mb-2 flex lg:flex-row flex-col justify-between items-center">
        <div class="px-12 py-6">
            <h1 
            class="text-blue-700 text-2xl sm:text-3xl 2xl:text-4xl font-normal text-left">
              <faIcon
              icon="file-alt"
              type="fas"
              class="text-green-500 text-3xl"
              >
              </faIcon>
              Lista de cotizaciones
            </h1>
            <p class="pt-1 text-md sm:text-lg text-gray-600 text-left">Aquí puedes ver un listado de cotizaciones filtradas según los campos que especifiques.</p>
        </div>
    </div>
    <ListTable
      :columns="fields"
      :filters="filters"
      rowsGetter="cotizacion/cotizaciones"
      :buttons="buttons"
      :managementButtons="true"
      :useStorage="true"
      :columnFilters="true"
      @mostrar-detalle="verCotizacion"
      @filter-input="setFilters"
      @dates-input="setDates"
    >
     
    <template v-slot:valorTotal="data">
      <p class="text-gray-900 whitespace-no-wrap">
        {{  `$ ${this.formatearCifra(data.value)}` }}
      </p>
    </template>
    </ListTable>
    <Pagination
      path=""
      :meta="meta"
      :links="links"
      action="cotizacion/paginateCotizaciones"
      v-if="meta && meta.last_page > 1"
    />
    <Modal 
    :textoBotonAction="textoBotonAction"
    :textoBotonCancel="textoBotonCancel"
    v-if="modalVisible"
    class="z-40"
    >
      <template v-slot:header>
          <div class="flex justify-between">
            <div class="text-left sm:pt-3 sm:pl-6 pl-2">
                <h3 class="font-medium text-2xl sm:text-3xl text-gray-700">Detalle de cotización</h3>
                <p class="mt-1 max-w-2xl text-sm sm:text-base text-gray-500">Aquí encontrarás la información asociada a esta cotización.</p>
            </div>
            <div class="text-2xl sm:text-3xl whitespace-no-wrap sm:pt-3 pr-2 sm:pr-6">
              Nº {{ this.cotizacionSeleccionadaVer.numeroCotizacion }}
            </div>
          </div>
      </template>

      <template v-slot:body>
        <DetalleCotizacion
        :cotizacion="cotizacionSeleccionadaVer"
        />
      </template>

      <template v-slot:botones>
        <div class="sm:flex sm:flex-row-reverse">
          <button @click="confirmarOT" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-xl sm:text-base font-normal focus:outline-none sm:ml-3 sm:w-auto bg-green-600 hover:bg-green-500 text-white">
            Generar OT
          </button>
          <button @click="this.cerrarModalCotizacion" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-xl sm:text-base font-normal focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto border-transparent bg-gray-600 hover:bg-gray-500 text-white">
            Salir
          </button>
        </div>
      </template>
    </Modal>
    <Modal
      :titulo="tituloConfirmacion"
      v-if="modalOTVisible"
      class="z-40"
    >
    <template v-slot:body>
      <div>
        <Form @submit="generarOT" :validation-schema="schema">
          <div class="flex flex-col">
            <p class="pt-6 text-gray-700 text-lg">
              Se ingresará la OT luego de realizar este primer abono: 
              </p>
            <div class="pb-8 pt-4 flex justify-center">
              <div class="flex flex-col text-center px-2 pt-3 relative text-xl max-w-sm">

                <div class="flex items-center">
                  <label class="mr-1 font-normal text-gray-900">Monto:</label>
                </div>
                <div class="relative">
                  <Field 
                    name="montoAbono"
                    class="form-input rounded-md text-right text-xl w-full border-gray-500 text-gray-900"
                    v-model="setMontoAbono"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    > 
                  </Field>
                  <ErrorMessage name="montoAbono" class="block absolute text-sm text-red-600 inset-x-0 text-right mr-2"/>
                </div>
                
                <div class="flex items-center pt-4">
                  <label class="font-normal text-gray-900">Medio pago:</label>
                </div>
                <div class="relative">
                  <Field 
                    name="medioPagoAbono"
                    as="select"
                    class="form-select rounded-md text-xl w-full border-gray-500 text-gray-900"
                    v-model="this.datosOT.abono.medioPagoAbono"
                    >
                    <option value="Efectivo"> Efectivo </option>
                    <option value="Transbank" selected> Transbank </option>
                    <option value="Cheque"> Cheque </option>
                    <option value="Convenio"> Convenio </option>
                  </Field>
                </div>

                <div class="flex items-center pt-4">
                  <label class="font-normal text-gray-900">Nº boleta:</label>
                </div>
                <div class="relative">
                  <Field 
                    name="numeroBoleta"
                    class="form-input rounded-md text-left text-xl w-full border-gray-500 text-gray-900"
                    v-model="datosOT.numeroBoleta"
                    onkeypress="return /[0-9]/i.test(event.key)"
                    > 
                  </Field>
                  <ErrorMessage name="numeroBoleta" class="block absolute text-sm text-red-600 inset-x-0 text-right mr-2"/>
                </div>

                <div class="flex items-center pt-4">
                  <label class="font-normal text-gray-900">Fecha de retiro:</label>
                </div>
                <div class="relative">
                  <DatePicker
                  :attributes="atributosDatePicker"
                  v-model="datosOT.fechaRetiro"
                  mode="date"
                  :model-config="modelConfig">
                    <template v-slot="{ inputValue, inputEvents }">
                        <input
                        name="fechaRetiro"
                        class="rounded-md w-full text-xl form-input box border-gray-500 text-center"
                        :value="inputValue"
                        v-on="inputEvents"
                        >
                    </template>
                  </DatePicker>

                  <ErrorMessage name="fechaRetiro" class="block absolute text-sm text-red-600 inset-x-0 text-right mr-2"/>
                </div>

                <div class="pt-4 text-gray-900">
                  Crédito:
                  <input type="checkbox" class="form-checkbox text-2xl border-gray-500" v-model="datosOT.credito">
                  <button 
                type="submit" 
                :disabled="estaInhabilitadoBotonAbono"
                class="mt-4 justify-center rounded-md border border-transparent shadow-sm px-4 py-3 text-base font-semibold focus:outline-none ml-3 sm:w-auto sm:text-md bg-green-600 hover:bg-green-500 text-white">
                    Abonar
                </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </template>

    <template v-slot:botones>
      <div>
        <button 
        @click="cerrarModalOT"
        class="rounded-md border shadow-md mx-1 px-4 py-2 text-base font-semibold focus:outline-none sm:mt-0 sm:w-auto sm:text-sm border-transparent bg-gray-600 hover:bg-gray-500 text-white">
          Cancelar
        </button>
      </div>
    </template>
    </Modal>
  </div>

  <Modal
  titulo="Voucher OT"
  textoBotonAction="Imprimir"
  class="z-40"
  @accion="imprimirVoucher('voucherOT')"
  @cerrar="cerrarModalVoucherOT"
  v-if="esVisibleVoucherOT"
  >
  <template v-slot:body>
    <Voucher
    id="voucherOT">
      <template v-slot:contenido>
        <VoucherOT
        :datosOT="datosVoucherOT"
      />
      </template>
    </Voucher>
  </template>
  </Modal>

</template>
<script>
import ListTable from "@/components/ListTable";
import Modal from "@/components/Modal";
import DetalleCotizacion from "@/components/cotizaciones/DetalleCotizacion";
import Pagination from "@/components/Pagination";
import Voucher from "@/components/Voucher";
import VoucherOT from "@/components/OTs/VoucherOT";

import { mapGetters } from "vuex";
import { Field, Form, ErrorMessage } from "vee-validate";
import { object, string } from "yup";
import { eliminarVacios, separarPorMiles, printSection } from "@/utils/helpers";
import { DatePicker } from "v-calendar"
import moment from "moment"

import store from "@/store/index";

import CotizacionService from "@/services/CotizacionService";
import OrdenTrabajoService from "@/services/OrdenTrabajoService";



export default {
  name: "ListadoCotizaciones",
  setup() {
    const schema = object({
      montoAbono: string().required('Ingresa un monto.'),
    });

    let initials = store.getters['cotizacion/filters']
    let dates = store.getters['cotizacion/dates']

      return {
        schema,
        fields: {
          'numeroCotizacion' : 'número',
          'nombre': 'cliente',
          'apellidoPaterno': 'Apellido paterno',
          'apellidoMaterno': 'Apellido materno',
          'fechaCreacion' : 'fecha',
          'horaCreacion': 'hora',
          'valorTotal' : 'total'
        },
        filters: [
          {
            "placeholder": "Nº",
            "type": "number",
            "field": "cotz_num",
            "initial": initials && initials.cotz_num ? initials.cotz_num.value : ""
          },
          {
            "placeholder": "Por cliente",
            "field": "cotz_nombre",
            "initial": initials && initials.cotz_nombre ? initials.cotz_nombre.value : ""
          },
          {
            "placeholder": "Por apellido",
            "field": "cotz_apellido_pat",
            "initial": initials && initials.cotz_apellido_pat ? initials.cotz_apellido_pat.value : ""
          },
          {
            "placeholder": "Por apellido materno",
            "field": "cotz_apellido_mat",
            "initial": initials && initials.cotz_apellido_mat ? initials.cotz_apellido_mat.value : ""
          },
          {
            "placeholder": "Por fecha",
            "field": "created_at",
            "type": "date-range",
            "initial": dates ? dates : null
          },
          {
            "type": "none"
          }
        ],
        buttons: [
          {
            "text": "Ver",
            "event": "mostrar-detalle",
          }
        ],
      }
  },
  data() {
    return {
      
      rows: [
      ],      
      modalVisible: false,
      textoBotonAction: 'Generar OT',
      textoBotonCancel: 'Salir',
      cotizacionSeleccionadaVer: null,
      numeroCotizacionSeleccionada: null,
      modalOTVisible: false,
      tituloConfirmacion: 'Ingresa un primer abono',
      datosOT: {
        cotizacion: null,
        abono: {
          montoAbono: 0,
          medioPagoAbono: 'Efectivo'
        },
        numeroBoleta: null,
        credito: false,
        fechaRetiro: moment(new Date()).format('DD-MM-YYYY')
      },
      estaFocusMontoAbono: false,
      estaInhabilitadoBotonAbono: false,
      datosVoucherOT: {
        numeroOT: 0,
        fecha: '00/00/0000',
        nombre: '',
        telefono: '',
        lentes: [],
        abono: 0,
        saldo: 0,
        total: 0,
        codigoOT: '',
        sucursal: '',
        nombreVendedor: ''
      },
      esVisibleVoucherOT: false,
      atributosDatePicker: [
        {
            key: 'today',
            highlight: true,
            dates: null,
        }
      ],
      modelConfig: {
        type: 'string',
        mask: 'DD-MM-YYYY'
      },
    }
  },
  computed: {
    ...mapGetters("cotizacion", ["error", "meta", "links"]),
    setMontoAbono: {
      get: function() {
        return `$ ${this.datosOT.abono.montoAbono.toFixed().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1.")}`
      },
      set: function(modifiedValue) {          
        let newValue = parseFloat(modifiedValue.replace(/[$.]/g, ""))        
        if (isNaN(newValue)) {
            newValue = 0;
        }        
        this.datosOT.abono.montoAbono = newValue
      }
    }
  },
  methods: {
    cargarListaCotizaciones() {
      store.dispatch("cotizacion/getCotizaciones", 1);
    },
    verCotizacion(value) {
      CotizacionService.getCotizacion(value.numeroCotizacion).then((response) => {
        this.cotizacionSeleccionadaVer = response.data.data;
        console.log(this.cotizacionSeleccionadaVer);
        this.numeroCotizacionSeleccionada = this.cotizacionSeleccionadaVer.numeroCotizacion;
      }).then(() => {
        this.abrirModalCotizacion();
      });
    },
    cerrarModalCotizacion() {
      this.modalVisible = false;
    },
    abrirModalCotizacion() {
      this.modalVisible = true;
    },
    abrirModalOT() {
      this.modalOTVisible = true;
    },
    cerrarModalOT() {
      this.modalOTVisible = false;
      this.estaInhabilitadoBotonAbono = false;
      this.limpiarCamposAbono();
    },
    generarOT() {
      if (this.datosOT.abono.montoAbono == 0 || this.datosOT.abono.montoAbono > this.cotizacionSeleccionadaVer.valorTotal) {
        this.emitter.emit("notification-set", {
          type: 'error',
          title: '¡Un momento!',
          text: 'No es posible realizar el abono. Verifica los datos ingresados y el saldo.',
          time: 10000,
          group: 'notifications'
        });
      }
      else{
        this.estaInhabilitadoBotonAbono = true;
        this.datosOT.cotizacion = this.cotizacionSeleccionadaVer;

        const payload = this.datosOT;

        eliminarVacios(payload);

        OrdenTrabajoService.storeOrdenTrabajo(payload)
        .then((response) => {
          let otIngresada = response.data;
          this.datosVoucherOT.numeroOT = otIngresada.ot_num;
          this.datosVoucherOT.fecha = otIngresada.fechaFormateada;
          this.datosVoucherOT.abono = this.datosOT.abono.montoAbono;
          this.datosVoucherOT.saldo = otIngresada.ot_saldo;
          this.datosVoucherOT.nombre = `${otIngresada.ot_nombre} ${otIngresada.ot_apellido_pat} ${otIngresada.ot_apellido_mat}`;
          this.datosVoucherOT.telefono = otIngresada.ot_telefono;
          this.datosVoucherOT.total = otIngresada.ot_valor_total;
          this.datosVoucherOT.codigoOT = otIngresada.ot_code;
          this.datosVoucherOT.sucursal = otIngresada.nombreSucursal;
          this.datosVoucherOT.nombreVendedor = otIngresada.ot_nombre_vendedor;
          this.datosVoucherOT.fechaRetiro = moment(otIngresada.ot_fecha_retiro).format('DD/MM/YYYY');

          // Se agregan los lentes a mostrar en el voucher a un array.
          let multifocalAgregado = false;

          for (let index = 0; index < this.cotizacionSeleccionadaVer.detalleCotizacion.length; index++) {
            const element = this.cotizacionSeleccionadaVer.detalleCotizacion[index];

            if ( element.tipoLente == 'Multifocal' ) {
              if ( !multifocalAgregado ) {
                let lente = {
                tipo: element.tipoLente, 
                valor: element.valorLente,
                armazon: element.armazon,
                filtro: element.tipoFiltro,
                cristal: element.tipoCristal,
                tipoMultifocal: element.tipoMultifocal 
                };

                this.datosVoucherOT.lentes.push(lente);

                multifocalAgregado = true;
              }
            } else {
              let lente = {
                tipo: element.tipoLente, 
                valor: element.valorLente,
                armazon: element.armazon,
                filtro: element.tipoFiltro,
                cristal: element.tipoCristal
                };

              this.datosVoucherOT.lentes.push(lente);
            }
          }

          this.esVisibleVoucherOT = true;
        })
        .then(() => {
          this.limpiarCamposAbono();
          this.cerrarModalOT();
          this.cerrarModalCotizacion();
          this.emitter.emit("notification-set", {
              type: 'success',
              title: '¡Todo en orden!',
              text: 'La orden de trabajo ha sido ingresada al sistema.',
              time: 10000,
              group: 'notifications'
          });
        })
        .catch((error) => {
          const responseData = error.response.data;

          if ( responseData.status === 'fail' ) {
            this.emitter.emit("notification-set", {
              type: 'error',
              title: '¡Un momento!',
              text: responseData.message,
              time: 10000,
              group: 'notifications'
            });

            this.estaInhabilitadoBotonAbono = false;

            return;
          }

          this.cerrarModalOT();
          this.limpiarCamposAbono();
          this.emitter.emit("notification-set", {
              type: 'error',
              title: '¡Un momento!',
              text: 'Ha ocurrido un imprevisto. Inténtalo de nuevo.',
              time: 10000,
              group: 'notifications'
          });
        });
      }
    },
    anularCotizacion(numCotz) {
      if (this.cotizacionSeleccionadaVer.estado != 0) {
        CotizacionService.nullateCotz(numCotz)
        .then(() => this.cargarListaCotizaciones())
        .then(() => this.cerrarModalCotizacion());
      }
    },
    confirmarOT() {
      this.abrirModalOT();
    },
    limpiarCamposAbono() {
      this.datosOT.abono.medioPagoAbono = 'Efectivo';
      this.datosOT.abono.montoAbono = 0;
      this.datosOT.numeroBoleta = null;
      this.datosOT.credito = false;
      this.datosOT.fechaRetiro = moment(new Date()).format('DD-MM-YYYY');
    },
    setDates(dates) {
      store.dispatch("cotizacion/setDates", dates);
      this.cargarListaCotizaciones();
    },
    setFilters(filters) {
      store.dispatch("cotizacion/setFilters", filters);
      this.cargarListaCotizaciones();
    },
    formatearCifra(cifra) {
      return separarPorMiles(cifra);
    },
    imprimirVoucher(idElemento) {
      printSection(idElemento);
    },
    limpiarCamposVoucherOT() {
      this.datosVoucherOT.numeroOT = 0;
      this.datosVoucherOT.fecha = '00/00/0000';
      this.datosVoucherOT.nombre = '';
      this.datosVoucherOT.telefono = '';
      this.datosVoucherOT.lentes = [];
      this.datosVoucherOT.abono = 0;
      this.datosVoucherOT.saldo = 0;
      this.datosVoucherOT.total = 0;
    },
    cerrarModalVoucherOT() {
      this.esVisibleVoucherOT = false;
      this.limpiarCamposVoucherOT();
    }
  },
  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page) || 1
    store.dispatch("cotizacion/getCotizaciones", currentPage).then(() => {
      to.params.page = currentPage
      next()
    })
  },
  components: {
    ListTable,
    Modal,
    DetalleCotizacion,
    Pagination,
    Field,
    Form,
    ErrorMessage,
    Voucher,
    VoucherOT,
    DatePicker
  }
}
</script>